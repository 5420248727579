import React from "react";
import "../assets/css/realschool-product-css.css"
import SEO from "../components/seo";

const RealschoolProduct = () => {
  return (
    <main>
      <section id="first__slide">
        <img
          src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/0004a0456bc8fc65f992f47d1b7384d2.svg"
          alt=""
        />
      </section>
      <section id="second__slide">
        <div className="second__slide-backdropone">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/5709d6d762114dac0dc06e4d2921de66.svg"
            alt=""
          />
        </div>
        <div className="second__slide-backdroptwo">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/5dc8dc7831afba6b04ea49dc322356d9.svg"
            alt=""
          />
        </div>
        <div className="aboutUfaber-FounderImageWrapper">
          <div className="uFaberLogo-AboutTextWrapper">
            <div className="ufaberLogo">
              <img
                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/8fee2e258df0de0932695821b2f6cf07.1682671168245283"
                alt=""
              />
            </div>
            <div className="aboutUfaberText">
              <p>About Ufaber</p>
              <p>
                Started by IIT Bombay Alumni in 2014. uFaber is the pioneer of
                online 1on1 education in India. From a small office of 5
                members, uFaber today is a company valued at 500 Crore, with
                offices in 3 major cities and Students from around the world
              </p>
            </div>
          </div>
          <div className="founderImageWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/7066d847d2aad05ec203d11cfd5fe2eb.png"
              alt=""
            />
          </div>
        </div>
        <div className="aboutuFaberPointsWrapper">
          <div className="aboutuFaberIndividualPoints">
            <span>4 Lakh+</span>
            <span>Students</span>
          </div>
          <hr />
          <div className="aboutuFaberIndividualPoints">
            <span>5000</span>
            <span>In House Teachers</span>
          </div>
          <hr />
          <div className="aboutuFaberIndividualPoints">
            <span>1000+</span>
            <span>Employee</span>
          </div>
          <hr />
          <div className="aboutuFaberIndividualPoints">
            <span>100+</span>
            <span>Courses</span>
          </div>
        </div>
        <div className="brandsGIFMainWrapper">
          <div className="brandGIFWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ac9957ea1f45d0d5d40619f4e12f469a.gif"
              alt=""
            />
          </div>
          <div className="brandGIFWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/4c3b2926ef68841fbfcd0098254ae320.gif"
              alt=""
            />
          </div>
          <div className="brandGIFWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/3d7dac7c120236d8f898f3f855a433fc.gif"
              alt=""
            />
          </div>
          <div className="brandGIFWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/be227beced4ca2d8a3c48bc7433a9195.gif"
              alt=""
            />
          </div>
          <div className="brandGIFWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/4955e18fa8aa572e8122c798e9585f2a.gif"
              alt=""
            />
          </div>
        </div>
      </section>
      <section id="third__slide">
        <div className="heading-realschoolLogoWrapper">
          <div className="third__slide-headingWrapper">
            <p>About Real school</p>
            <p>
              India’s Only <span>Personalized</span> School
            </p>
          </div>
          <div className="realschoolLogo">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/5d77a41efd0d2c68795c26b958a47d00.svg"
              alt=""
            />
          </div>
        </div>
        <div className="realschoolPoints-ChildPlayingImageWrapper">
          <div className="realschoolPointsWrapper">
            <div className="realschoolIndividualPoints">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/d1c5647facab69b9df52e3b5fded10a1.svg"
                alt=""
              />
              <p>Pre-primary Montessori</p>
            </div>
            <div className="realschoolIndividualPoints">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/093812b95c1c75cbfe4c446a01e02601.svg"
                alt=""
              />
              <p>Primary International school</p>
            </div>
            <div className="realschoolIndividualPoints">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/d911d0db9be07f007dadc82e36e94893.svg"
                alt=""
              />
              <p>Special education for learning disabilities</p>
            </div>
          </div>
          <div className="childPlayingImage">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/eabc58c149b02a58e55028b387ea2ed5.png"
              alt=""
            />
          </div>
          <div className="indiaMapBackdrop">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/2757bc0729695e6347585db99d046630.svg"
              alt=""
            />
          </div>
        </div>
        <div className="realschoolOneInMilWrapper">
          <img
            src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/c2dc49d0070aa60e684ff9ef89f46a4a.1682680605616283"
            alt=""
          />
          <p>Real school is one in a million</p>
        </div>
        <div className="realschoolStatsWrapper">
          <div className="realschoolIndividualStats">
            <span>1000+</span>
            <p>Students in 1st year</p>
          </div>
          <div className="realschoolIndividualStats">
            <span>97%</span>
            <p>Parents find their kids transformed</p>
          </div>
        </div>
        <div className="schoolBackedByRIWrapper">
          <p>School backed by Research and Innovation</p>
          <div className="schoolBackedByRI-imageWrapper">
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/b2dabd7eaa1b54124bac05f1a4542dff.168327066198254"
              alt=""
            />
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/074797d1f7e782bbaba41c76d3775be9.gif"
              alt=""
            />
          </div>
        </div>
        <div className="handsOnPracticalWrapper">
          <p>Hands on and Practical to the core</p>
          <div className="handsOnPracticalWrapper-imageWrapper">
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/79ce7ecb3ea8b180315f34d31b61aea6.168313419832334"
              alt=""
            />
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/4493e5a8d883ad3b8973fbaeb7f29e17.gif"
              alt=""
            />
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/3ccb1a31b315790f7c5567a1fac14927.gif"
              alt=""
            />
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/a8ab79a3cec0b095672915b4980c4222.168313423714734"
              alt=""
            />
          </div>
        </div>
        <p className="startedTeamwithPedgreePassionText">
          Started by a Team with Pedigree and Passion
        </p>
        <div className="TeamImageInfoWrapper">
          <div className="indiviualTeamMemberImageInfo">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/062e88cff4ff9e7f6d2b6b143755a1c1.png"
              alt=""
            />
            <div className="teamMemberName-InfoWrapper">
              <span>Anirudh Swarnkar</span>
              <p>
                Anirudh has experience developing products and content. He has a
                strong commitment to supporting young children's development.
              </p>
            </div>
          </div>
          <div className="indiviualTeamMemberImageInfo">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/7c4ad77a0f3cf4d9519177ecd75bbbb1.png"
              alt=""
            />
            <div className="teamMemberName-InfoWrapper">
              <span>Rohit Jain</span>
              <p>
                Rohit has had 2 successful education startup exist and has
                kickstarted several trends In Indian education industry in the
                15 years, real school is the best version of his experiences,
                successes and hard learned lessons.
              </p>
            </div>
          </div>
          <div className="indiviualTeamMemberImageInfo">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/00ddb0615c3210e9ef255d8bef3a7f04.png"
              alt=""
            />
            <div className="teamMemberName-InfoWrapper">
              <span>Niraj</span>
              <p>
                Before Real School, Niraj ran a company which was into
                industrial automation. He has designed everything from a jet
                nozzle to a cargo ship. This hard core technocrat holds a
                management degree from IIM Indore.
              </p>
            </div>
          </div>
          <div className="indiviualTeamMemberImageInfo">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/c9ff95c7c9316dc5ce5c1feec617aa9e.png"
              alt=""
            />
            <div className="teamMemberName-InfoWrapper">
              <span>Vikas</span>
              <p>
                Vikas is dedicated to transforming the way children look at the
                world by inspiring a spirit for inquiry and curiosity
              </p>
            </div>
          </div>
        </div>
        <p className="ourParentCanSeeTheTransformationText">
          Our parent can see the transformation
        </p>
        <div className="parentsImageNameFeedbackWrapper">
          <div className="individual-ParentImageNameFeedback">
            <a href="https://www.youtube.com/embed/_aOtw4RWlx4" target="_blank" className="individual-ParentImageWrapper">              
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/320cb4c22dd44f3d71d950e4128480c5.png"
                alt=""
              />
              <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/177744ee26e8fced362e2fbf458a9aa6.168320252669844" alt="" className="playButton" />
            </a>
            <span>Advika’s Father</span>
            <p>
              Real school have interesting classes and immersive learning
              experience
            </p>
          </div>
          <div className="individual-ParentImageNameFeedback">
            <a href="https://www.youtube.com/embed/UApr10auyaM" target="_blank" className="individual-ParentImageWrapper"> 
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/2c1656a35a348c8663c13249f05deb7f.png"
                alt=""
              />
              <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/177744ee26e8fced362e2fbf458a9aa6.168320252669844" alt="" className="playButton"/>
            </a>
            <span>Adrienna's Father</span>
            <p>Real school competition was very motivating for him.</p>
          </div>
          <div className="individual-ParentImageNameFeedback">
            <a href="https://www.youtube.com/embed/nOqBH5tLv24" target="_blank" className="individual-ParentImageWrapper">             
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1899a9c325c3d11649e3b2fedf5c243.png"
                alt=""
              />
              <img src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/177744ee26e8fced362e2fbf458a9aa6.168320252669844" alt="" className="playButton"/>
            </a>
            <span>Shrujan's Mother</span>
            <p>Real school topics are new and exciting.</p>
          </div>
        </div>
      </section>
      <section id="fourth__slide">
        <p className="fourth__slide-heading">
          What’s the <span>Montessori</span> method?
        </p>
        <div className="montessoriMethodImage">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/01542a0beff28723c804a80eef85d558.svg"
            alt=""
          />
        </div>
        <p className="fourth__slide-subheading">
          The Four Planes Of <span>Development</span>
        </p>
        <div className="FourPlanesOfDevelopmentImage">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/20556b0859500b3dd3f4855532d8033e.svg"
            alt=""
          />
        </div>
        <div className="mariaMontessoriImageQuite">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ec7c770fbda4b02c670e052c8b22f072.png"
            alt=""
          />
        </div>
      </section>
      <section id="fifth__slide">
        <p className="fifth__slide-heading">
          Montessori <span>sensitive</span> periods
        </p>
        <div className="fifth__slide-graphImage">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/350943345b20ab405ab76f692fe3f61e.svg"
            alt=""
          />
        </div>
      </section>
      <section id="seventh__slide">
        <p className="seventh__slide-heading">
          Difference between A <span>Montessori</span> child and{" "}
          <span>Traditional</span> child
        </p>
        <div className="childrenStudyingImageWrapper">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ee8b8d34a793ea017b08bac33db18295.png"
            alt=""
          />
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/157879aa4254107b5961204573e6c0c7.png"
            alt=""
          />
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/54e5e0c6ea1b18d3bae39db906e29a18.png"
            alt=""
          />
        </div>
        <div className="seventh__slide-tableWrapper">
          <img
            src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/868667d59400634fb09dd409d4ae3cd4.168296478357714"
            alt=""
          />
        </div>
      </section>
      <section id="eighth__slide">
        <p className="eighth__slide-heading">
          Even founders of <span>Google</span> and <span>Amazon</span> went to
          Montessori schools!
        </p>
        <div className="foundersImageWrapper">
          <div className="individualFoundersImageWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/67876dd678f7a741291cb55a72b12112.png"
              alt=""
            />
            <p>
              Larry page &amp; Sergey Brin, <span>Founders of Google</span>
            </p>
          </div>
          <div className="individualFoundersImageWrapper">
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/582a42be2d0476451d295e79a398da56.168296509674814"
              alt=""
            />
            <p>
              Jeff Bezos, <span>Founder of Amazon</span>{" "}
            </p>
          </div>
          <div className="individualFoundersImageWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/6b00957a1e3e8613f6b94c65c70b607a.png"
              alt=""
            />
            <p>
              Stephen Curry, <span>professional basketball player</span>
            </p>
          </div>
        </div>
      </section>
      <section id="ninth__slide">
        <p className="ninth__slide-heading">
          The <span>Secret</span> Sauce!
        </p>
        <div className="ninth__slide-imagesWrapper">
          <div className="ninth__slide-IndividualImagesWrapper">
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/623ed0ed63ca6dedc3123e27e5996bd0.168296581976024"
              alt=""
            />
            <p>A Passionate and well trained Montessori “Directress”</p>
          </div>
          <div className="ninth__slide-IndividualImagesWrapper">
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/27362b1890da727839097c66cc28cc8b.168296582674824"
              alt=""
            />
            <p>Montessori principles, materials and environment</p>
          </div>
        </div>
      </section>
      <section id="tenth__slide">
        <div className="tenth__slide-headingImageWrapper">
          <p>How we create a directress</p>
          <div className="emaesterLogoTextWrapper">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e1ae7993ff913e2c07e2ccadc8b00f52.svg"
              alt=""
            />
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/1dcf0737b264dea6231b927e25685653.168301256158324"
              alt=""
            />
          </div>
        </div>
        <div className="tenth__slide-roadmapimagewrapper">
          <img
            src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/e166d20268f053d5a9322e41e60ef504.168301294592024"
            alt=""
          />
        </div>
        <div className="tenth__slide-lastTableWrapper">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/4994e2a95f6c7e135cc1dc245da33164.svg"
            alt=""
          />
        </div>
      </section>
      <section id="sixth__slide">
        <p className="sixth__slide-heading">
          What do <span>You</span> Learn
        </p>
        <div className="sixth__slide-moduleCard">
          <div className="moduleCard-TopSection">
            <span>Module 1</span>
          </div>
          <div className="moduleCard-BottomSection">
            <p>montessori pedagogy &amp; practical life</p>
            <ul>
              <li>
                Detail exposure to the development of the whole child, and the
                successive phases of child development.
              </li>
              <li>
                Discussions on absorbent mind, sensitive periods, 4 planes of
                development, principles of Montessori
              </li>
              <li>
                Practical Life: Preliminary activities, Self-care, Care of
                environment, Grace and Courtesy
              </li>
            </ul>
          </div>
          <div className="moduleCard-TopImagesWrapper">
            <div className="moduleCard-TextImageWrapper">
              <span>Care of Self</span>
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ca5dbd34f0b526cafc43a958c4f13b9b.png"
                alt=""
              />
            </div>
            <div className="moduleCard-TextImageWrapper">
              <span>Care of&nbsp;Environment</span>
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/2743b81a75e025a894a31ac017ad2f74.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="sixth__slide-moduleCard">
          <div className="moduleCard-TopSection">
            <span>Module 2</span>
          </div>
          <div className="moduleCard-BottomSection">
            <p>Sensorial Activities</p>
            <ul>
              <li>Sensorial Work - The Principles and their Purpose</li>
              <li>
                Conducting Three Period Lesson’ and understanding&nbsp; ‘Control
                of Error’
              </li>
              <li>
                Presenting Sensorial Materials as tools for Refinement of
                Senses&nbsp;
              </li>
              <li>How to implement The Principle of Isolation</li>
              <li>
                In depth knowledge of Sensorial Materials and how to present
                them
              </li>
            </ul>
          </div>
          <div className="moduleCard-TopImagesWrapper">
            <div className="moduleCard-TextImageWrapper">
              <span>Cylinder blocks&nbsp;(Visual Sense)</span>
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/3cc0bd4cae82bb7999383b5ba33a9d48.png"
                alt=""
              />
            </div>
            <div className="moduleCard-TextImageWrapper">
              <span>Geometric solids (Stereognostic sense)</span>
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/7d2f78027c4e1a784341657b0e72ddf8.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="sixth__slide-moduleCard">
          <div className="moduleCard-TopSection">
            <span>Module 3</span>
          </div>
          <div className="moduleCard-BottomSection">
            <p>Language</p>
            <ul>
              <li>
                In depth knowledge of language materials and how to present
              </li>
              <li>
                Adept at building Phoneme awareness to develop early Reading
                Skills
              </li>
              <li>Linear and Parallel progression</li>
              <li>
                Proficiency in teaching correct Structure, Syntax and Grammar.
              </li>
              <li>Getting familiar with children’s literature</li>
            </ul>
          </div>
          <div className="moduleCard-TopImagesWrapper">
            <div className="moduleCard-TextImageWrapper">
              <span>Sandpaper&nbsp;letters</span>
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/fc04ba7fa162b3a9d07db1bcb41c8d05.png"
                alt=""
              />
            </div>
            <div className="moduleCard-TextImageWrapper">
              <span>Movable&nbsp;alphabets</span>
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/44c17ccfcded50141092f63eabd2c7fc.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="sixth__slide-moduleCard">
          <div className="moduleCard-TopSection">
            <span>Module 4</span>
          </div>
          <div className="moduleCard-BottomSection">
            <p>Mathematics</p>
            <ul>
              <li>
                Proficiency in&nbsp; the five groups of Montessori Mathematics -
                Numeracy, Decimal System,Linear Counting, Memorization &amp;
                Passage to Abstraction
              </li>
              <li>
                Detailed knowledge of inter - relationships between the 5 groups
              </li>
              <li>Linear and Parallel Progression</li>
              <li>Proficiency is progressing from Concrete to Abstract</li>
            </ul>
          </div>
          <div className="moduleCard-TopImagesWrapper">
            <div className="moduleCard-TextImageWrapper">
              <span>Spindle box</span>
              <img
                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/17562d7804f19669dcfb41a38dd183bc.1682686909546283"
                alt=""
              />
            </div>
            <div className="moduleCard-TextImageWrapper">
              <span>Teen beads and teen boards</span>
              <img
                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/527685cbd4b8c884ffda04caf4ba2ad1.1682686918324283"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="sixth__slide-moduleCard">
          <div className="moduleCard-TopSection">
            <span>Module 5</span>
          </div>
          <div className="moduleCard-BottomSection">
            <p>Culture</p>
            <ul>
              <li>
                Presenting Cultural lessons as ‘Keys’ to further exploration of
                the real world
              </li>
              <li>
                Learning to stimulate curiosity to make connections between
                items shown and the ‘real world’
              </li>
              <li>
                Adept at developing observation and exploration with a
                Scientific mind
              </li>
            </ul>
          </div>
          <div className="moduleCard-TopImagesWrapper">
            <div className="moduleCard-TextImageWrapper">
              <span>World Map and Flags</span>
              <img
                src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/17c4e311a7e38406874845f7d755963e.1682686927228283"
                alt=""
              />
            </div>
            <div className="moduleCard-TextImageWrapper">
              <span>Botany cabinets</span>
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/f969dee62200762571f9ecd6ee45ca7b.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      <section id="eleventh__slide">
        <p className="eleventh__slide-heading">
          Montessori <span>home school</span> offerings
        </p>
        <div className="homeSchoolOfferingCardWrapper">
          <div className="individualHomeSchoolOfferingCard">
            <div className="individualHomeSchoolOfferingCard-TopImage">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/2c69eb4fcd11858ff5ad2a63b55a823f.svg"
                alt=""
              />
            </div>
            <p className="homeSchoolOfferingCard-Title">
              Pre primary Montessori program
            </p>
            <ul>
              <li>-Age : 2.5 to 6 years</li>
              <li>-Montessori curriculum</li>
              <li>-Class strength : 15 to 29</li>
              <li>-Engaging sessions</li>
              <li>-Real school tool kit</li>
              <li>-Hands on learning</li>
            </ul>
          </div>
          <div className="individualHomeSchoolOfferingCard">
            <div className="individualHomeSchoolOfferingCard-TopImage">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/c80657e2ea41e6f4f5733246e1b2fc48.svg"
                alt=""
              />
            </div>
            <p className="homeSchoolOfferingCard-Title">
              Mother Toddler Program
            </p>
            <ul>
              <li>-Age : 0 to 3 years</li>
              <li>-Engaging sessions</li>
              <li>-Best parenting practice</li>
              <li>-Principles of Montessori</li>
              <li>-Parenting tips</li>
            </ul>
          </div>
          <div className="individualHomeSchoolOfferingCard">
            <div className="individualHomeSchoolOfferingCard-TopImage">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/d15cd6a8b89083c8a9ab9620130d8d70.svg"
                alt=""
              />
            </div>
            <p className="homeSchoolOfferingCard-Title">Masterclasses</p>
            <ul>
              <li>-Age : 3 to 10 years</li>
              <li>-Engaging sessions</li>
              <li>-Hands on Workshops</li>
              <li>- Diverse Topics: Arts to Science</li>
              <li>-Activity and Game based learning</li>
            </ul>
          </div>
        </div>
      </section>
      <section id="twelfth__slide">
        <p className="twelfth__slide-heading">
          Where does <span>REAL SCHOOL</span> come in?
        </p>
        <ul>
          <li>
            <span>Provide Training</span> and make you into a world class
            trained, certified Montessori directress
          </li>
          <li>
            Provide training programs in both{" "}
            <span>“Pre-Primary and Toddler”</span> categories, giving you a high
            earning potential in a small locality
          </li>
          <li>
            Provide required <span>Montessori Materials</span> to set-up your
            home space in the minimum cost in Industry (25k vs 1 to 5 lakhs)
          </li>
          <li>
            Provide a <span>Brand name and Team backing</span> that adds trust
            and backing to your school
          </li>
          <li>
            <span>Dedicated Homeschool support manager</span> to help you with
            administrative help
          </li>
          <li>
            <span>Dedicated Academic support manager</span> to help you with day
            to day academic help
          </li>
          <li>
            Ongoing Training in Montessori pedagogy and make you an{" "}
            <span>education influencer</span> in your locality{" "}
          </li>
        </ul>
      </section>
      <section id="thirteenth__slide">
        <p className="thirteenth__slide-heading">Earning opportunities&nbsp;</p>
        <div className="earningOpportunitiesTableImage">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/e79b556e8d291f6190f0f31b9497cfa2.svg"
            alt=""
          />
        </div>
        <p className="thirteenth__slide-subheading">
          Return on Investment&nbsp;
        </p>
        <div className="returnOnInvestmentInfoWrapper">
          <p>
            Fee to Real School <span>@ 1 Lakh</span> for 6 months
          </p>
          <p>
            Micro-school operational costs @ <span>5,000 per month</span>{" "}
            (30,000 over 6 months)
          </p>
          <div className="detailedROI-btnwrapper">
            <p>Detailed ROI study:</p>
            <a
              href="https://drive.google.com/file/d/1kRvpH-yn6wYe4bf92Awn2qPq4u0gYyKl/view"
              className="thirteenth__slide-viewBtn"
              target="_blank"
            >
              View
            </a>
          </div>
        </div>
        <div className="thirteenth__slide-lastRoadMapImage">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/b5ada703f15c082928e050e176994b21.svg"
            alt=""
          />
        </div>
      </section>
      <section id="fourteenth__slide">
        <p className="fourteenth__slide-heading">
          Real School Engagement fee Breakup
        </p>
        <div className="engagementFeeBreakupImage-ListWrapper">
          <div className="engagementFeeBreakupImageWrapper">
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/b58bc5a547b9285cd46c666cfcdd28e7.1687447089617225"
              alt=""
            />
          </div>
          <ul>
            <li>Revenue share per enrollment - 0</li>
            <li>Annual License and renewal fee - 10,000</li>
          </ul>
        </div>
        <p className="fourteenth__slide-subheading">
          Real school VS other approaches
        </p>
        <div className="fourteenth__slide-LastTableImage">
          <img
            src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/da4d6df627bb6416c740b5a998102c36.svg"
            alt=""
          />
        </div>
      </section>
      <section id="fifteenth__slide">
        <div className="fifteenth__slide-headingImageCollageWrapper">
          <div>
            <p className="fifteenth__slide-heading">
              Montessori <span>Home-school</span> concept is already revolution
              abroad
            </p>
            <div className="IndianFlag-IndiaIsWaitingTextWrapper">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/ad0f15c53f61b6f10ce4fa8f17fa6d1c.svg"
                alt=""
              />
              <p>“India is in the waiting”</p>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <img
              src="http://s3.us-east-2.wasabisys.com/ufaber-lms/files/f6d2871ebef6ac515d3b8da583a1da3e.168303819087724"
              alt=""
            />
          </div>
          <div className="fifteenth__slide-backdrop">
            <img
              src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/702ac6597ca3c3acaa1fd29ba442ed8c.svg"
              alt=""
            />
          </div>
        </div>
      </section>
      <section id="sixteenth__slide">
        <p className="sixteenth__slide-heading">What next?</p>
        <div className="whatNextCardWrapper">
          <div className="whatNextStep-CardWrapper">
            <span className="stepText">Step 01</span>
            <div className="whatNextIndividualCard">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/50829664908d339e267546c9000c9384.svg"
                alt=""
              />
              <span>Applicant Eligibility form</span>
              <p>
                Applicant will need to fill a detailed eligibility form for Real
                School to understand fitment of association
              </p>
            </div>
          </div>
          <div className="whatNextStep-CardWrapper">
            <span className="stepText">Step 02</span>
            <div className="whatNextIndividualCard">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/3b43722e76447dbece37bd0c306dd26f.svg"
                alt=""
              />
              <span>Verification and Audit</span>
              <p>
                Real School will conduct a virtual space audit and verification
                call to check authenticity of all the submitted details
              </p>
            </div>
          </div>
          <div className="whatNextStep-CardWrapper">
            <span className="stepText">Step 03</span>
            <div className="whatNextIndividualCard">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/255d444e7aab13b0a89b94c2e809ad06.svg"
                alt=""
              />
              <span>LOU and Contract</span>
              <p>
                Both the parties enter into a letter of understanding and sign a
                contractual agreement to initiate the association and finalize
                the start date
              </p>
            </div>
          </div>
          <div className="whatNextStep-CardWrapper">
            <span className="stepText">Step 04</span>
            <div className="whatNextIndividualCard">
              <img
                src="https://s3.us-east-2.wasabisys.com/ufaber-lms/files/f2f807a360cd55e92e83d5db892ba3a2.svg"
                alt=""
              />
              <span>Association begins</span>
              <p>
                Training starts followed by sequential launch of programs at the
                owner’s homeschool
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export const Head = () => {
    return (
        <SEO 
            description="start your own montessori school from home"
            title=" Real School of Montessori"
            viewport="width=device-width, initial-scale=0, user-scalable=no"
        />
        
    )
}
export default RealschoolProduct;
